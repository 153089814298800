root {
  --card-drop-shadow: 0 0 16px 0 #0000000a;
}

.card {
  padding: var(--8px);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--card-drop-shadow);
  background-color: var(--color-background-grey);
}

.card-primary {
  border-radius: var(--8px);

  .card-title {
    align-items: center;
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-white);
    padding: var(--6px) var(--6px) var(--6px) var(--16px);
    border-radius: var(--8px) var(--8px) 0 0;
    gap: var(--8px);
    height: 50px;
    max-height: 50px;

    &.card-title-small {
      height: 25px;
      max-height: 25px;
    }
  }

  @media (max-width: 768px) {
    .card-title {
      max-height: unset;
      height: unset;
    }
  }

  .card-body {
    border: var(--2px) solid var(--color-primary-8-transparent);
    border-top: none;
    border-radius: 0 0 var(--8px) var(--8px);
    padding: var(--8px);
  }
}

.card-white {
  background-color: var(--color-white);
}

.card-info {
  background-color: var(--color-white);
  border-radius: var(--8px);
  padding: var(--16px);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--8px);
  border: 2px solid transparent;

  &.selected {
    border: 2px solid var(--color-primary);
  }

  &.border {
    border: 1px solid var(--color-primary);
  }

  &-content {
    display: flex;
    align-items: center;
    width: 100%;

    input[type='radio'] {
      margin-right: var(--16px);
      accent-color: var(--color-primary);
    }
  }

  .user-info,
  .conversation-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--4px);
  }

  &.warning {
    background-color: var(--color-background-error);
    border: var(--2px) solid var(--color-background-error);
  }

  hr {
    margin: var(--8px) 0;
    height: 1px;
    border: none;
    background-image: linear-gradient(to right, var(--color-background-grey) 50%, transparent 50%);
    background-size: 16px 1px;
  }

  h2 {
    display: flex;
    align-items: baseline;
    gap: var(--8px);
    font-size: var(--16px);
    text-transform: capitalize;

    span {
      &:nth-of-type(1) {
        color: var(--color-black);
      }

      &:nth-of-type(2) {
        color: var(--color-secondary-grey);
      }
    }
  }

  > div {
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    &.user-contact-info div,
    &.conversation-info div {
      gap: var(--4px);
    }
  }
}
