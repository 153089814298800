.gradient-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-primary);

  .gradient-inner-container {
    background: var(--color-background-content, #f7f7f7);
    height: 95%;
    width: 95%;
    max-width: 900px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow-y: auto;

    .header {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 0 16px 0 #00000014;
      z-index: 1;

      .logo {
        margin-bottom: 24px;
      }

      .info {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.83px;
      }
    }
  }
}

.scroll-h {
  overflow-x: auto;
}

.scroll-v {
  overflow-y: auto;
}
